import * as React from "react"
import { DateTime } from "luxon";
import Link from 'components/link'

const EventCard = ({ event, index = 0, category = false }) => {

	const cardColors = [
		'#9AC3DB',
		'#C6DCEB',
		'#CFE6F1',
		'#CFE6F1',
		'#C6DCEB',
		'#9AC3DB',
	];

	const dateFormat = 'EEE, MMM d';
	const now = new Date();

	const startDate = DateTime.fromISO(event.actualStartDate, { zone: 'utc'});
	const endDate = DateTime.fromISO(event.actualEndDate, { zone: 'utc'});
	const diff = startDate.diff(endDate, 'days');

	// const diff = new Date(event.startDate) - new Date(event.endDate);
	const color = cardColors[index % cardColors.length];
	let dateString = '';
	if (startDate.monthShort !== endDate.monthShort) {
		// format like Wed, May 8 - Thu Jun 6
		dateString = `${startDate.toFormat('EEE, MMM d')} - ${endDate.toFormat('EEE, MMM d')}${endDate.year !== now.getFullYear() ? ` ${endDate.toFormat('y')}` : ''}`;
	} else if (diff.values.days >= 1) {
		// format like Wed, May 8 - 12
		dateString = `${startDate.toFormat('EEE, MMM d')} - ${endDate.toFormat('d')}${endDate.year !== now.getFullYear() ? ` ${endDate.toFormat('y')}` : ''}`;
	} else {
		dateString = startDate.toFormat(dateFormat);
	}

	if (event.time) dateString += ` @ ${event.time} ${event.timeDetails ? event.timeDetails : ''}`;
	if (event.recurringEvent) dateString += <div className="event-type">Recurring Event</div>;
	else if (event.multidayEvent) dateString += <div className="event-type">Multiday Event</div>;

	if (!!event.category === false) {
		if (event.Category.length > 1)event.category = 'Multiple Categories';
		else if (event.Category.length === 1) {
			event.category = event.Category[0].object.name;
		} else {
			event.category = '';
		}
	}

	if (!!event.neighborhood === false) {
		if (event.Neighborhood.length > 1)event.neighborhood = 'Multiple Neighborhoods';
		else if (event.Neighborhood.length === 1) {
			event.neighborhood = event.Neighborhood[0].object.name;
		} else {
			event.neighborhood = '';
		}
	}

	const categoryString = category ? ` • ${event.category}` : '';
	return (
		<div key={index} className="event-card">
			<Link to={event.link}>
				<div className="background" style={{ backgroundColor: color }}></div>
				<div className="content-container">
					<div className="title">{event.name}</div>
					<div className="date">{dateString}</div>
					<div className="neighborhood">{event.neighborhood}{categoryString}</div>
				</div>
			</Link>
		</div>
	);
}

export default EventCard;