import { DateTime } from "luxon";

export const filterEvents = (
	eventsArray,
	searchStartRange,
	searchEndRange,
	limit = 3,
	weekly = false
) => {

	if (weekly === true) searchStartRange = (DateTime.utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
	if (weekly === true) searchEndRange = (DateTime.utc().endOf('week'));

	let startRange = searchStartRange;
	let endRange = searchEndRange;

	if (!!searchStartRange !== false && typeof searchStartRange === 'object' && searchStartRange.constructor.name === 'Date')startRange = DateTime.fromJSDate(searchStartRange);
	if (!!searchEndRange !== false && typeof searchEndRange === 'object' && searchEndRange.constructor.name === 'Date')endRange = DateTime.fromJSDate(searchEndRange);
	if (!!searchStartRange !== false && typeof searchStartRange === 'string')startRange = DateTime.fromISO(searchStartRange, {zone: 'utc'});
	if (!!searchEndRange !== false && typeof searchEndRange === 'string')endRange = DateTime.fromISO(searchEndRange, {zone: 'utc'});
	if (!!searchStartRange !== false && typeof searchStartRange === 'number')startRange = DateTime.fromMillis(searchStartRange, {zone: 'utc'});
	if (!!searchEndRange !== false && typeof searchEndRange === 'number')endRange = DateTime.fromMillis(searchEndRange, {zone: 'utc'});

	if (startRange) { // Make sure the ranges are at the start and end of the day
		startRange.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
	}
	if (endRange) { // Make sure the ranges are at the start and end of the day
		endRange.set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
	}

	let cards;

	if (!!startRange === false && !!endRange === false) {
		cards = eventsArray;
	} else {
		cards = eventsArray.filter((block) => {
			if (block.actualStartDate === undefined && block.dates && block.dates.length > 0){
				const filtered = block.dates.filter((dateBlock) => {
					const actualDate = DateTime.fromISO(dateBlock.date, {zone: 'utc'});
					const diff = actualDate.diffNow('days');
					return diff.values.days >= 0;
				});
				if (filtered.length > 0)block.actualStartDate = filtered[0].date;
			}
			if (!!block.actualStartDate === false)return false;
			const blockStartDate = DateTime.fromISO(block.actualStartDate, {zone: 'utc'});
			let diffFromStartRange;
			let diffFromEndRange;
			if (startRange) {
				 diffFromStartRange = blockStartDate.diff(startRange, 'days');
			}
			if (endRange) {
				diffFromEndRange = blockStartDate.diff(endRange, 'days');
			}
			if (!endRange && diffFromStartRange.values.days >= 0)return true;
			if (endRange && diffFromStartRange.values.days >= 0 && diffFromEndRange.values.days <= 0)return true;
			return false;
		});
	}

	if (!!limit && !!cards.length) cards.length = limit;

	return cards.sort((a, b) => {
		const aStartDate = new Date(a.startDate);
		const bStartDate = new Date(b.startDate);
		return aStartDate - bStartDate;
	});
}