import { graphql } from "gatsby";

import Content from "components/templates/Content";
import Layout from 'components/templates/Layout';
import Link from 'components/link';
import DataProvider from 'providers/data';
import React from "react";

import EventCard from "components/templates/events/EventCard";

import { filterEvents } from 'helpers/events';
import useLiveData from 'hooks/useLiveData';
import {DateTime} from 'luxon';

const SimpleViewCategoryList = props => {
	const { edges } = props.data.allContentPage;
	const nodes = edges.map(({ node }) => node);

	const { edges: eventNodes } = props.data.allEventsEvent;
	let eventsArr = eventNodes.map(({ node }) => node);
	eventsArr.forEach((event) => {
		event.dates = event.dates.filter((dateBlock) => {
			const actualDate = DateTime.fromISO(dateBlock.date, {zone: 'utc'});
			const diff = actualDate.diffNow('days');
			return diff.values.days >= 0;
		});
		if (event.dates.length > 0) {
			event.actualStartDate = event.dates[0].date;
			event.actualEndDate = event.dates[0].date;
		}
		event.link = `/events/${event.permalink}/`;

	});
	const events = filterEvents(eventsArr, null, null, 6, true)

	let page = nodes[0];

	const { statusText: pageProviderStatusText, response: pageProviderResponse } = useLiveData({
		type: 'ContentPage',
		apiParams: {
			uri: "/listings/",
		},
		staticData: props.data,
	});

	if ((!!pageProviderResponse !== false && pageProviderResponse.data.length > 0) && pageProviderStatusText === 'LOADED') {
		page = pageProviderResponse.data[0];
	}

	let { nodes: catNodes } = props.data.allSimpleviewCategory;

	if ((props.providerResponse.data.length > 0 || !!catNodes !== false) && props.providerStatusText === 'LOADED') {
		catNodes = props.providerResponse.data;
	}

	const whitelist = [
		"culinary-experiences",
		"outdoors-nature",
		"shopping",
		"golf"
	];


	catNodes = catNodes.filter(cat => whitelist.includes(cat.permalink))

	return (
		<Layout>
			<Content
				headlineAlignment="center"
				headlineTitle={page?.headlineTitle}
				headlineBody={page?.headlineBody}
				headlineImage={page?.headlineImage}
				page={page}
			>
				{events.length > 0 && (
					<section id="events">
						<div className="grid-container">
							<div className="grid-x grid-margin-x align-center">
								<div className="cell">
									<h3 style={{ marginBottom: "1rem" }}>Upcoming Events</h3>
								</div>
								<div className="cell events-flx">
									{events.map((event, index) => {
										return (
											<EventCard key={`${event.id}-${index}`} event={event} index={index} category={true} />
										)
									})}
								</div>
								<div style={{ marginTop: "1rem", marginBottom: "2.5rem" }} className="cell text-center">
									<Link to="/events/" className="button">View All Events</Link>
								</div>
							</div>
						</div>
					</section>
				)}
			</Content>
			<div className="grid-container">
				<div className="grid-x grid-margin-x">
					<div className="cell content-page">
						<h3>Explore</h3>
					</div>
				</div>
				<div className="grid-x grid-margin-x small-up-1 medium-up-2 large-up-3">
					{catNodes.filter(cate => !!cate?.Listing.length).map((cat, index) => (
						<div style={{ marginBottom: "27px" }} key={`${cat.id}-${index}`} className="cell medium-4 text-left" >
							<div className="news-card flex-container flex-dir-column align-items">
								<div className="news-photo" style={{ backgroundImage: `url(${cat.photo})` }}></div>
								<div className="news-card-content">
									<h2 style={{ alignSelf: 'flex-start' }} className="news-title">{cat.name}</h2>
									<p className="news-text">{cat.description}</p>
								</div>
								<Link className="button" to={`/listings/${cat.permalink}/`}>Read More</Link>
							</div>
						</div>
					))}
				</div>
			</div>
		</Layout>
	);
}

const getData = (props) => <DataProvider liveRefresh={props.pageContext.liveRefresh} type="SimpleviewCategory" apiParams={{ approved: true, deleted: false, archived: false, _join: "Listing" }}><SimpleViewCategoryList {...props} /></DataProvider>
export default getData;

export { Head } from 'components/templates/Head';

export const query = graphql`
query SimpleViewCategoryListQuery {
	allContentPage(
	  filter: {uri: {eq: "/listings/"}, deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}
	) {
	  edges {
		node {
		  pageTitle
		  meta
		  content {
			main
		  }
		  headlineBody
		  headlineTitle
		  headlineImage
		}
	  }
	}
	allEventsEvent(
		filter: {approved: {eq: true}, archived: {eq: false}, deleted: {eq: false}}
		sort: {order: [DESC, DESC], fields: [showDate, name]}
	  ) {
		edges {
		  node {
			id
			city
			contactEmail
			contactName
			contactPhone
			cost
			dates {
			  time
			  startTime
			  endTime
			  date
			  realDate
			}
			description
			email
			featured
			firstDate
			firstTime
			intro
			isMultiDay
			isRecurring
			linkText
			location
			name
			poster
			permalink
			phone
			photo
			recurringEvent
			registrationDetail
			registration
			registrationEmail
			registrationLimit
			registrationLimitTable {
			  date
			  limit
			}
			registrationMessage
			showDate
			sort
			state
			street
			suite
			timeDetails
			zip
			Category {
			  object {
				name
				permalink
			  }
			}
			Neighborhood {
			  object {
				name
				permalink
			  }
			}
		  }
		}
	}
	allSimpleviewCategory(
	  filter: {approved: {eq: true}, archived: {eq: false}, deleted: {eq: false}}
	  sort: {fields: sort, order: DESC}
	) {
	  nodes {
		id
		name
		photo
		description
		permalink
		Listing {
			object {
				id
			}
		}
	  }
	}
}
`